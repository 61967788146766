$(window).load(function(){
	function dataResize(){
		var max = {};
		$('[data-resize]').each(function(){
			var id = $(this).attr('data-resize');
			if (!max.hasOwnProperty(id)) {
				max[id] = $(this).height();
			} else {
				if ($(this).height() > max[id]) {
					max[id] = $(this).height();
				}							
			}
		});
		$.each(max, function(index, value){
			$('[data-resize=' + index + ']').height(value);
		})		
	}
	if ($(window).width() >= 768){
    	dataResize();
    }
    $(window).resize(function(){
    	if ($(window).width() >= 768){
        	dataResize();
        }
    })
});