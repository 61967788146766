$(function(){
    var toggleWall = {};
    $('.js-toggle-wall').click(
        function(e){
            var id = $('.js-toggle-wall');
            var anchor = $('.js-toggle-wall-id');
            var time = 300;
            var w = anchor.innerWidth();
            if (!toggleWall.hasOwnProperty(id)){
                toggleWall[id] = false
            }
            if (!toggleWall[id]) {
                anchor.animate({
                    right: 0
                }, parseInt(time), function(){
                    toggleWall[id] = true;
                });
                if ($('header').hasClass("scroll")) {
                    $('body,html,header').animate({
                        left: '-'+w
                    }, parseInt(time)); 
                } else {
                    $('body,html').animate({
                        left: '-'+w
                    }, parseInt(time)); 
                }
                anchor.addClass('open');
                $('body').addClass('no-scroll');
                $('.navbar-toggle').addClass('active');                   
            } else {
                anchor.animate({
                    right: '-'+w
                }, parseInt(time), function(){
                    toggleWall[id] = false;
                });
                if ($('header').hasClass("scroll")) {
                    $('body,html,header').animate({
                        left: 0
                    }, parseInt(time));
                } else {
                    $('body,html').animate({
                        left: 0
                    }, parseInt(time));
                }   
                anchor.removeClass('open');
                $('body').removeClass('no-scroll'); 
                $('.navbar-toggle').removeClass('active');                    
            }

        }
    );
    $(window).resize(function(){
        var id = $('.js-toggle-wall');
        var anchor = $('.js-toggle-wall-id')
        var time = 300;
        var w = anchor.innerWidth();

        $('.navbar-toggle').removeClass('active');
        anchor.animate({
            right: '-'+w
        }, parseInt(time), function(){
            toggleWall[id] = false;
        });
        if ($('header').hasClass("scroll")) {
            $('body,html,header').animate({
                left: 0
            }, parseInt(time));
            anchor.removeClass('open');
        } else {
            $('body,html').animate({
                left: 0
            }, parseInt(time));
            anchor.removeClass('open');
        }
        $('body').removeClass('no-scroll');                       
    })
});