$(function(){

	var status = false;

	function setNavbarClass(init){
		var h = $(window).scrollTop()
		var elem = $('.js-navbar')

		if (h >= 200 && !status) {

			elem.addClass('scroll');
			status = true;

		} else if ((h >= 0 && h < 200) && status) {

			elem.removeClass('scroll');
			status = false;

		}		

	}

	$(window).bind('scroll touchmove', function(){

		setNavbarClass();

	})

	setNavbarClass(true);

	if ($(window).width() < 768) {
	    $(document)
	    .on('focus', 'input', function() {
	        $('body').addClass('fixfixed');
	    })
	    .on('blur', 'input', function() {
	        $('body').removeClass('fixfixed');
	    });		
	}

});